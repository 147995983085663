import React from 'react'
import PropTypes from 'prop-types'

const ProcessCard = ({ icon, title, description }) => {
    return (
        <div className="book-consultation__card">
            <div
                className={`icon icon--dark ${icon} icon--book-consultation-size`}
            ></div>
            <h2 className="book-consultation__card-title headline headline--h3">
                {title}
            </h2>
            <p className="book-consultation__card-description paragraph paragraph--md paragraph--dark">
                {description}
            </p>
            <div className="divider divider--md divider--blue divider--thick"></div>

            {/* DO NOT REMOVE. PurgeCSS will remove it not applied. */}
            <div
                className="icon--calendar icon--apply icon--discuss"
                style={{ visibility: 'hidden' }}
            ></div>
        </div>
    )
}

ProcessCard.propTypes = {}

export default ProcessCard

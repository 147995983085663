import React from 'react'
import PropTypes from 'prop-types'
import ProcessCard from './ProcessCard'

const ProcessSection = ({ process }) => {
    return (
        <div className="book-consultation__cards">
            {process &&
                process.map(item => (
                    <ProcessCard
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                    />
                ))}
        </div>
    )
}

ProcessSection.propTypes = {}

export { ProcessSection }

import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Container, Section } from '../components/Common'
import { MainHero } from '../components/Hero'
import { ProcessSection } from '../components/ProcessSection'
import { BookConsultationForm } from '../components/FormSections'
import { useBookConsultation } from '../GraphQL/Queries'
import htmr from 'htmr'

const BookConsultation = () => {
    const data = useBookConsultation()

    const hero = {
        image: data.acf_hero.heroImage.sourceUrlSharp.childImageSharp.fluid,
        alt: data.acf_hero.heroImage.altText,
        title: data.acf_hero.bbTitle,
        description: data.acf_hero.bbDescription,
    }

    const pageContent = {
        header: data.acf_bookConsultationContent.header,
        description: data.acf_bookConsultationContent.mainDescription,
        process: data.acf_bookConsultationContent.process,
        form: {
            title: data.acf_bookConsultationContent.form.title,
            description: data.acf_bookConsultationContent.form.description,
        },
        footer: {
            description:
                data.acf_bookConsultationContent.footerSection.description,
            includes: data.acf_bookConsultationContent.footerSection.includes,
        },
    }

    return (
        <Layout pageClass={'book-consultation'}>
            <SEO title={data.title} />
            <MainHero
                alt={hero.alt}
                image={hero.image}
                title={hero.title}
                description={hero.description}
                large
            />
            <Section>
                <Container>
                    <div className="book-consultation__header-block">
                        <h3 className="headline headline--h4">
                            {htmr(pageContent.header)}
                        </h3>
                        <p className="paragraph paragraph--lg paragraph--dark">
                            {htmr(pageContent.description)}
                        </p>
                    </div>
                </Container>
            </Section>

            <Section>
                <Container>
                    <div className="book-consultation__process">
                        <h2 className="book-consultation__process-title headline headline--h2">
                            HERE'S HOW IT WORKS
                        </h2>
                        <div className="divider divider--full divider--blue" />
                        <ProcessSection process={pageContent.process} />
                    </div>
                </Container>
            </Section>

            <Section>
                <Container>
                    <div className="book-consultation__bottom-block">
                        <div className="book-consultation__bottom-block-description">
                            <p className="paragraph paragraph--lg paragraph--dark">
                                {htmr(pageContent.footer.description)}
                            </p>
                        </div>
                        <div className="book-consultation__bottom-block-divider" />
                        <div className="book-consultation__bottom-block-includes">
                            <h3 className="headline headline--h3">
                                YOUR FREE
                                <br />
                                CONSULTATION INCLUDES:
                            </h3>
                            <ul>
                                {pageContent &&
                                    pageContent.footer.includes.map(item => (
                                        <li>{item.item}</li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </Container>
            </Section>
            <Section dark>
                <Container>
                    <BookConsultationForm
                        title={pageContent.form.title}
                        description={pageContent.form.description}
                    />
                </Container>
            </Section>
        </Layout>
    )
}

export default BookConsultation
